import { Injectable } from '@angular/core'
import { Calendar, Child, CompletePage, ErrorMessage, Header, Homepage, InputError, Parent, RequestService, Service } from '../models/language.model'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  countryLanguage!: string

  constructor() {
    this.country
    // console.log('this.countryLanguage', this.countryLanguage)
    // console.log('this.country', this.country)

  }

  // Get countryId from URL
  get country() {

    let countryUrl = window.location.hostname.split('.').pop()!.toUpperCase()
    let listCountry = ['AR', 'BO', 'BR', 'CL', 'CO', 'PE', 'UY']
    let index = listCountry.indexOf(countryUrl)

    if (index > -1) {
      this.countryLanguage = listCountry[index]
      return listCountry[index]
    } else {
      this.countryLanguage = 'BR'
      return 'BR'
    }
  }

  selectLanguage(arr: any[]) {

    const findCountry = arr.find(item => item.country === this.countryLanguage)
    const countryNotFound = arr.find(item => item.country === 'HH')

    return findCountry ? findCountry : countryNotFound
  }

  get header() {
    let content: Header[] = [
      {
        country: 'BR',
        welcome: 'SEJA BEM-VINDO À UNIDADE',
        contact: 'CONTATO',
        labelMeetCenter: 'CONHEÇA A UNIDADE',
        labelMethod: 'MÉTODO',
        labelDepositions: 'DEPOIMENTOS',
        linkMeetCenter: 'conheca-mais-a-unidade',
        linkMethod: 'metodo-kumon',
        linkDepositions: 'depoimentos-pais-e-alunos'
      },
      {
        country: 'HH',
        welcome: 'BIENVENIDOS A LA UNIDAD',
        contact: 'CONTÁCTANOS',
        labelMeetCenter: 'CONOCE LA UNIDAD',
        labelMethod: 'MÉTODO',
        labelDepositions: 'TESTIMONIOS',
        linkMeetCenter: 'conoce-la-unidad',
        linkMethod: 'metodo-kumon',
        linkDepositions: 'testimonio-de-padres-y-alumnos'
      }
    ]

    return this.selectLanguage(content)
  }

  get homePage() {

    let content: Homepage[] = [
      {
        country: 'BR',
        labelService: 'Selecione o modo de atendimento',
        labelCalendar: 'Selecione o dia',
        labelTimeSlot: 'Selecione o horário',
        labelParent: 'Dados do responsável',
        labelChild: 'Dados do aluno',
        labelRequired: '(Obrigatório)',
        labelOptional: '(Opcional)',
        timezone: 'Fuso horário',
        timeNotFindText: 'Não encontrou um horário que te atende?',
        timeNotFindLink: 'Envie um e-mail',
        privacyText: 'Li e estou ciente da',
        privacyLink: 'política de privacidade',
        privacyURL: 'politica-de-privacidade',
        buttonSchedule: 'Agendar',
        buttonReschedule: 'Reagendar',
        buttonTryAgain: 'Tentar novamente',
        loadingCenterInfo: 'Carregando as informações da unidade',
        loadingUpdateCalendar: 'Atualizando calendário',
        loadingFinishAppointment: 'Estamos realizando seu agendamento, aguarde um momento',
        loadingUpdateAppointment: 'Estamos atualizando seu agendamento, aguarde um momento'
      },
      {
        country: 'HH',
        labelService: 'Seleccione el modo de servicio',
        labelCalendar: 'Seleccione el día',
        labelTimeSlot: 'Seleccione la hora',
        labelParent: 'Datos del responsable',
        labelChild: 'Datos del alumno',
        labelRequired: '(Obligatorio)',
        labelOptional: '(Opcional)',
        timezone: 'Huso horario',
        timeNotFindText: '¿No encontraste un horario que funcione para ti?',
        timeNotFindLink: 'Enviar un e-mail',
        privacyText: 'He leído y estoy al tanto de',
        privacyLink: 'política de privacidad',
        privacyURL: 'politica-de-privacidad',
        buttonSchedule: 'Agendar',
        buttonReschedule: 'Reprogramar',
        buttonTryAgain: 'Intentar nuevamente',
        loadingCenterInfo: 'Cargando la información de la unidad',
        loadingUpdateCalendar: 'Actualización de calendario',
        loadingFinishAppointment: 'Estamos haciendo su cita, por favor espere un momento',
        loadingUpdateAppointment: 'Estamos actualizando su horario, por favor espere un momento'
      }
    ]

    return this.selectLanguage(content)
  }

  get completePage() {
    let content: CompletePage[] = [
      {
        country: 'BR',
        textSuccess: 'Agendamento foi confirmado com sucesso!',
        textCancel: 'Seu agendamento foi cancelado.',
        textTitle: 'Prepare-se para seu compromisso',
        textEmail: 'Seu agendamento foi realizado com sucesso! Um e-mail de confirmação, com mais detalhes, foi enviado para:',
        textEmailNotFound: '(Caso não o encontre em sua caixa de entrada, favor verificar as caixas de promoções e/ou spam de seu provedor de e-mail.)',
        textAdvanceNotice: 'Recomendamos comparecer à unidade com 15 minutos de antecedência no dia agendado.',
        textAboutKumon: 'A avaliação gratuita te possibilitará conhecer melhor o método Kumon, ter um melhor entendimento da atual situação de aprendizagem (sua ou de seu filho) e alinhar com o orientador o melhor plano de estudo para que possa alcançar seu máximo potencial.',
        textThankYou: 'Obrigado por seu interesse e nos vemos em breve!',
        textInfoOffline: 'Aguardamos você na unidade:',
        textInfoOnline: 'Agendado para a unidade:',
        textVirtualService: 'Atendimento virtual',
        textAccessLink: 'Link de acesso para atendimento virtual',
        textWeAreSorry: 'Sentimos muito que não será possível receber você na data e horário reservados.',
        textFindNewAppointment: 'Clique no botão abaixo para encontrar um novo dia e horário que melhor atenda as suas necessidades.',
        buttonBackToCenterPage: 'Voltar para a página da unidade',
        buttonReschedule: 'Reagendar',
        buttonCancel: 'Cancelar agendamento',
        buttonNewAppointment: 'Novo agendamento',
        buttonTryAgain: 'Tentar novamente',
        loadingCancel: 'Aguarde, estamos cancelando seu agendamento.',
        loadingUpdateInfoAppoitment: 'Atualizando informações do agendamento.',
        alertRescheduleTitle: 'Gostaria de reagendar seu atendimento?',
        alertCancelTitle: 'Deseja realmente cancelar seu agendamento?',
        alertConfirmReschedule: 'Sim, reagendar',
        alertConfirmCancel: 'Sim, cancelar',
        alertButtonCancel: 'Não, obrigado'
      },
      {
        country: 'HH',
        textSuccess: '¡La cita ha sido confirmada con éxito!',
        textCancel: 'Su cita ha sido cancelada.',
        textTitle: 'Prepárese para su cita',
        textEmail: '¡Su cita se programó con éxito! Se ha enviado un e-mail de confirmación con más detalles a:',
        textEmailNotFound: '(si no lo encuentra en su bandeja de entrada, consulte las carpetas de promociones y/o spam de su proveedor de correo electrónico).',
        textAdvanceNotice: 'Recomendamos llegar a la unidad con 15 minutos de anticipación el día programado.',
        textAboutKumon: 'La evaluación gratuita le permitirá conocer mejor el método Kumon, tener una mejor comprensión de la situación de aprendizaje actual (la suya o la de su hijo) y alinear con el asesor el mejor plan de estudio para que pueda alcanzar su máximo potencial.',
        textThankYou: '¡Gracias por su interés y hasta pronto!',
        textInfoOffline: 'Te esperamos en la unidad:',
        textInfoOnline: 'Programado para la unidad:',
        textVirtualService: 'Servicio virtual',
        textAccessLink: 'Link de acceso al servicio virtual',
        textWeAreSorry: 'Lamentamos mucho que no será posible recibirle en la fecha y hora reservada.',
        textFindNewAppointment: 'Haga clic en el botón de abajo para encontrar un nuevo día y hora que mejor se adapte a sus necesidades.',
        buttonBackToCenterPage: 'Volver a la página de la unidad',
        buttonReschedule: 'Reprogramar',
        buttonCancel: 'Cancelar cita',
        buttonNewAppointment: 'Nuevo horario',
        buttonTryAgain: 'Intentar nuevamente',
        loadingCancel: 'Por favor espere, estamos cancelando su cita.',
        loadingUpdateInfoAppoitment: 'Actualización de la información del horario.',
        alertRescheduleTitle: '¿Te gustaría reprogramar tu cita?',
        alertCancelTitle: '¿Realmente quieres cancelar tu cita?',
        alertConfirmReschedule: 'Si, reprogramar',
        alertConfirmCancel: 'Si, cancelar',
        alertButtonCancel: 'No, gracias'
      }
    ]

    return this.selectLanguage(content)
  }

  get service() {
    let content: Service[] = [
      {
        country: 'BR',
        serviceOnline: 'Atendimento Online',
        serviceoffline: 'Atendimento Presencial'
      },
      {
        country: 'HH',
        serviceOnline: 'Servicio Online',
        serviceoffline: 'Servicio en persona'
      }
    ]

    return this.selectLanguage(content)
  }

  get calendar() {

    let content: Calendar[] = [
      {
        country: 'BR',
        dayNames: [
          'Domingo',
          'Segunda',
          'Terça',
          'Quarta',
          'Quinta',
          'Sexta',
          'Sábado',
        ],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        monthNames: [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ],
        monthNamesShort: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
        today: 'Hoje',
        clear: 'Limpar'
      },
      {
        country: 'HH',
        dayNames: [
          'Domingo',
          'Lunes',
          'Martes',
          'Miércoles',
          'Jueves',
          'Viernes',
          'Sábado',
        ],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'],
        dayNamesMin: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'],
        monthNames: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre',
        ],
        monthNamesShort: [
          'Ene',
          'Feb',
          'Mar',
          'Abr',
          'May',
          'Jun',
          'Jul',
          'Ago',
          'Sep',
          'Oct',
          'Nov',
          'Dic',
        ],
        today: 'Hoy',
        clear: 'Limpiar'
      }
    ]

    return this.selectLanguage(content)
  }

  get parent() {

    let content: Parent[] = [
      {
        country: 'BR',
        labelFullName: 'Nome completo',
        labelEmail: 'E-mail',
        labelCellphone: 'Celular',
      },
      {
        country: 'HH',
        labelFullName: 'Nombre completo',
        labelEmail: 'E-mail',
        labelCellphone: 'Celular',
      }
    ]

    return this.selectLanguage(content)
  }

  get child() {

    let content: Child[] = [
      {
        country: 'BR',
        labelName: 'Primeiro nome',
        labelSchoolGrade: 'Série escolar',
        buttonAddChild: 'Adicionar filho',
        buttonRemoveChild: 'Remover',
        labelChildData: 'Dados do(s) aluno(s):'
      },
      {
        country: 'HH',
        labelName: 'Primer nombre',
        labelSchoolGrade: 'Grado escolar',
        buttonAddChild: 'Añadir niño',
        buttonRemoveChild: 'Quitar',
        labelChildData: 'Datos del alumno(s):'
      }
    ]

    return this.selectLanguage(content)
  }

  get requestService() {
    let content: RequestService[] = [
      {
        country: 'BR',
        modalTitle: 'Solicite um atendimento',
        labelFullName: 'Nome completo',
        labelEmail: 'E-mail',
        labelCellphone: 'Celular',
        labelMessage: 'Mensagem',
        requiredFields: 'Todos os campos são obrigatórios.',
        buttonSend: 'Enviar',
        buttonSending: 'Enviando...',
        alertSuccessTitle: 'Email enviado com sucesso!',
        alertSuccesstext: '',
        alertErrorTitle: 'Erro ao enviar email.',
        alertErrortext: 'Tente novamente mais tarde.',
        textInfoPart1: 'Você pode entrar em contato com a unidade',
        textInfoPart2: 'através do formulário abaixo, ou então pesquisar outra unidade',
        textInfoPart3: 'no site',
        pathSearchCenter: 'busca-de-unidades'
      },
      {
        country: 'HH',
        modalTitle: 'Solicitar un servicio',
        labelFullName: 'Nombre completo',
        labelEmail: 'E-mail',
        labelCellphone: 'Celular',
        labelMessage: 'Mensaje',
        requiredFields: 'Todos los campos son obligatorios.',
        buttonSend: 'Enviar',
        buttonSending: 'Enviando...',
        alertSuccessTitle: '¡Email enviado con éxito!',
        alertSuccesstext: '',
        alertErrorTitle: 'Error al enviar e-mail.',
        alertErrortext: 'Vuelva a intentarlo más tarde.',
        textInfoPart1: 'Puede ponerse en contacto con la unidad',
        textInfoPart2: 'a través del siguiente formulario, o busque otra unidad',
        textInfoPart3: 'en el sitio',
        pathSearchCenter: 'busqueda-de-unidades'
      }
    ]

    return this.selectLanguage(content)
  }

  get errorMessage() {
    let content: ErrorMessage[] = [
      {
        country: 'BR',
        centerInfoNotLoaded: 'Aconteceu algum problema e por isso não possível carregar as informações da unidade.',
        centerUnavailable: 'A unidade que você escolheu não tem mais horários disponíveis no momento.',
        createBooking: 'Aconteceu algum problema ao realizar o agendamento. Por favor, tente novamente em alguns minutos.',
        unableToCancel: 'Aconteceu algum problema e por isso não foi possível realizar o cancelamento.'
      },
      {
        country: 'HH',
        centerInfoNotLoaded: 'Hubo un problema y por lo tanto no fue posible cargar la información de la unidad.',
        centerUnavailable: 'La unidad que eligió actualmente no tiene citas disponibles.',
        createBooking: 'Hubo un problema para hacer la cita. Vuelva a intentarlo en unos minutos.',
        unableToCancel: 'Hubo un problema y por lo tanto no fue posible cancelar.'
      }
    ]

    return this.selectLanguage(content)
  }

  get inputError() {

    let content: InputError[] = [
      {
        country: 'BR',
        required: 'Campo obrigatório.',
        fullName: 'Por favor, preencha o nome completo.',
        invalidEmail: 'E-mail inválido.',
        invalidPhone: 'Celular incompleto.'
      },
      {
        country: 'HH',
        required: 'Campo obligatorio.',
        fullName: 'Por favor, ingrese el nombre completo.',
        invalidEmail: 'E-mail inválido.',
        invalidPhone: 'Celular incompleto.'
      }
    ]

    return this.selectLanguage(content)
  }

  get schoolGrade() {

    let content = [
      {
        country: 'BR',
        options: [
          { nome: 'Faltam 4 anos ou mais para 1º ano EF', valor: "PK3" },
          { nome: 'Faltam 3 anos para 1º ano EF', valor: "PK2" },
          { nome: 'Faltam 2 anos para 1º ano EF', valor: "PK1" },
          { nome: 'Falta 1 ano para 1º ano EF', valor: "K" },
          { nome: '1º ano - Ensino Fundamental', valor: "1" },
          { nome: '2º ano - Ensino Fundamental', valor: "2" },
          { nome: '3º ano - Ensino Fundamental', valor: "3" },
          { nome: '4º ano - Ensino Fundamental', valor: "4" },
          { nome: '5º ano - Ensino Fundamental', valor: "5" },
          { nome: '6º ano - Ensino Fundamental', valor: "6" },
          { nome: '7º ano - Ensino Fundamental', valor: "7" },
          { nome: '8º ano - Ensino Fundamental', valor: "8" },
          { nome: '9º ano - Ensino Fundamental', valor: "9" },
          { nome: '1º ano - Ensino Médio', valor: "10" },
          { nome: '2º ano - Ensino Médio', valor: "11" },
          { nome: '3º ano - Ensino Médio', valor: "12" },
          { nome: 'Adulto', valor: "13" },
          { nome: 'N/A', valor: "14" }
        ]
      },
      {
        country: 'CL',
        options: [
          { nome: 'Medio menor', valor: "PK3" },
          { nome: 'Medio mayor', valor: "PK2" },
          { nome: 'Pre kinder', valor: "PK1" },
          { nome: 'kinder', valor: "K" },
          { nome: 'Primero básico', valor: "1" },
          { nome: 'Segundo básico', valor: "2" },
          { nome: 'Tercero básico', valor: "3" },
          { nome: 'Cuarto básico', valor: "4" },
          { nome: 'Quinto básico', valor: "5" },
          { nome: 'Sexto básico', valor: "6" },
          { nome: 'Séptimo básico', valor: "7" },
          { nome: 'Octavo básico', valor: "8" },
          { nome: 'Primero medio', valor: "9" },
          { nome: 'Segundo medio', valor: "10" },
          { nome: 'Tercero medio', valor: "11" },
          { nome: 'Cuarto medio', valor: "12" },
          { nome: 'Adulto', valor: "13" },
          { nome: 'N/A', valor: "14" }
        ]
      },
      {
        country: 'CO',
        options: [
          { nome: 'Párvulos', valor: "PK3" },
          { nome: 'Prekinder', valor: "PK2" },
          { nome: 'kinder', valor: "PK1" },
          { nome: 'Transición', valor: "K" },
          { nome: '1', valor: "1" },
          { nome: '2', valor: "2" },
          { nome: '3', valor: "3" },
          { nome: '4', valor: "4" },
          { nome: '5', valor: "5" },
          { nome: '6', valor: "6" },
          { nome: '7', valor: "7" },
          { nome: '8', valor: "8" },
          { nome: '9', valor: "9" },
          { nome: '10', valor: "10" },
          { nome: '11', valor: "11" },
          { nome: 'Universitario', valor: "12" },
          { nome: 'Adulto', valor: "13" },
          { nome: 'Alumnos con necesidades especiales', valor: "14" }
        ]
      },
      {
        country: 'AR',
        options: [
          { nome: 'Hasta 3 años', valor: "PK3" },
          { nome: '3 años', valor: "PK2" },
          { nome: '4 años', valor: "PK1" },
          { nome: '5 años', valor: "K" },
          { nome: '1° grado', valor: "1" },
          { nome: '2° grado', valor: "2" },
          { nome: '3° grado', valor: "3" },
          { nome: '4° grado', valor: "4" },
          { nome: '5° grado', valor: "5" },
          { nome: '6° grado', valor: "6" },
          { nome: '7° grado', valor: "7" },
          { nome: '8° grado', valor: "8" },
          { nome: '9° grado', valor: "9" },
          { nome: '10° grado', valor: "10" },
          { nome: '11° grado', valor: "11" },
          { nome: '12° grado', valor: "12" },
          { nome: 'Adulto', valor: "13" },
          { nome: 'No aplica grado escolar', valor: "14" }
        ]
      },
      {
        country: 'BO',
        options: [
          { nome: 'Pre 4 o menos', valor: "PK3" },
          { nome: 'Pre 5', valor: "PK2" },
          { nome: 'Pre 6', valor: "PK1" },
          { nome: 'kinder', valor: "K" },
          { nome: '1º P (1º ano de primária)', valor: "1" },
          { nome: '2º P (2º ano de primária)', valor: "2" },
          { nome: '3º P (3º ano de primária)', valor: "3" },
          { nome: '4º P (4º ano de primária)', valor: "4" },
          { nome: '5º P (5º ano de primária)', valor: "5" },
          { nome: '6º P (6º ano de primária)', valor: "6" },
          { nome: '1º S (1º ano de secundária)', valor: "7" },
          { nome: '2º S (2º ano de secundária)', valor: "8" },
          { nome: '3º S (3º ano de secundária)', valor: "9" },
          { nome: '4º S (4º ano de secundária)', valor: "10" },
          { nome: '5º S (5º ano de secundária)', valor: "11" },
          { nome: '6º S (6º ano de secundária)', valor: "12" },
          { nome: 'Adultos', valor: "13" },
          { nome: 'EE', valor: "14" }
        ]
      },
      {
        country: 'PE',
        options: [
          { nome: '>F3', valor: "PK3" },
          { nome: 'F3', valor: "PK2" },
          { nome: 'F2', valor: "PK1" },
          { nome: 'F1', valor: "K" },
          { nome: '1G', valor: "1" },
          { nome: '2G', valor: "2" },
          { nome: '3G', valor: "3" },
          { nome: '4G', valor: "4" },
          { nome: '5G', valor: "5" },
          { nome: '6G', valor: "6" },
          { nome: '7G', valor: "7" },
          { nome: '8G', valor: "8" },
          { nome: '9G', valor: "9" },
          { nome: '10G', valor: "10" },
          { nome: '11G', valor: "11" },
          { nome: 'UNIV', valor: "12" },
          { nome: 'AD', valor: "13" },
          { nome: 'N/A', valor: "14" }
        ]
      },
      {
        country: 'UY',
        options: [
          { nome: 'Pre 4 o menos', valor: "PK3" },
          { nome: 'Pre 5', valor: "PK2" },
          { nome: 'Pre 6', valor: "PK1" },
          { nome: 'Kinder', valor: "K" },
          { nome: '1º P (1º ano de primária)', valor: "1" },
          { nome: '2º P (2º ano de primária)', valor: "2" },
          { nome: '3º P (3º ano de primária)', valor: "3" },
          { nome: '4º P (4º ano de primária)', valor: "4" },
          { nome: '5º P (5º ano de primária)', valor: "5" },
          { nome: '6º P (6º ano de primária)', valor: "6" },
          { nome: '1º S (1º ano de secundária)', valor: "7" },
          { nome: '2º S (2º ano de secundária)', valor: "8" },
          { nome: '3º S (3º ano de secundária)', valor: "9" },
          { nome: '4º S (4º ano de secundária)', valor: "10" },
          { nome: '5º S (5º ano de secundária)', valor: "11" },
          { nome: '6º S (6º ano de secundária)', valor: "12" },
          { nome: 'Adultos', valor: "13" },
          { nome: 'EE', valor: "14" }
        ]
      },
    ]

    return this.selectLanguage(content).options
  }

}
