import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms'
import { Component, Input, OnInit } from '@angular/core'
import { DadosService } from 'src/app/services/dados.service'

import * as _ from 'lodash'
import { Disciplinas } from 'src/app/models/disciplinas.model'
import { LanguageService } from 'src/app/services/language.service'
import { Child } from 'src/app/models/language.model'

@Component({
  selector: 'app-dados-aluno',
  templateUrl: './dados-aluno.component.html',
  styleUrls: ['./dados-aluno.component.scss']
})
export class DadosAlunoComponent implements OnInit {

  countryId = this.languageService.country
  language: Child = this.languageService.child
  schoolGrade = this.languageService.schoolGrade

  serviceNotes = ''
  form!: FormGroup

  @Input() disciplinas: Disciplinas[] = []

  constructor(
    private fb: FormBuilder,
    private dados: DadosService,
    private languageService: LanguageService
  ) {
    this.form = fb.group({
      children: this.fb.array([
        new FormGroup({
          name: new FormControl(''),
          schoolGrade: new FormControl(''),
          classSubjects: new FormArray([])
        })
      ])
    })
  }

  ngOnInit(): void {

    this.form.valueChanges.subscribe(val => {
      this.createHtmlTable(val.children)
    })

  }

  children(): FormArray {
    return this.form.get('children') as FormArray
  }

  newChild(): FormGroup {
    return this.fb.group({
      name: new FormControl(''),
      schoolGrade: new FormControl(''),
      classSubjects: new FormArray([])
    })
  }

  addChild() {
    this.children().push(this.newChild())
    console.log(this.form.get('children')?.value)
  }

  removeChild(i: number) {
    this.children().removeAt(i)
  }

  onCheckChange(event: any, index: number) {
    const children = (this.form.get('children') as FormArray)
    const disciplinas = (children.controls[index].get('classSubjects') as FormArray)

    if (event.target.checked) {
      disciplinas.push(new FormControl(event.target.value))

    } else {
      const index = disciplinas.controls
        .findIndex(x => x.value === event.target.value)
      disciplinas.removeAt(index)
    }
  }


  createHtmlTable(form: any) {
    let tr = ''
    let table = ''
    let classSubjects

    for (let i = 0; i < form.length; i++) {

      classSubjects = form[i].classSubjects.map((item: any) => ' ' + item)

      console.log(_.orderBy(classSubjects))


      // tr += `<tr>
      //             <td style="border:1px solid #ddd; padding: 5px;">${form[i].name}</td>
      //             <td style="border:1px solid #ddd; padding: 5px;">${form[i].schoolGrade}</td>
      //             <td style="border:1px solid #ddd; padding: 5px;">${_.orderBy(classSubjects)}</td>
      //           </tr>`

      // table = `<table style="font-size: 15px; font-family: sans-serif; display: inline-block" cellspacing="0" cellpadding="0" border="0">
      //             <tr>
      //               <td style="border-right:1px solid #fff;background-color: #A7DFF9; padding: 5px;"><b>Aluno</b></td>
      //               <td style="border-left:1px solid #fff; border-right:1px solid #fff;background-color: #A7DFF9; padding: 5px;"><b>Série</b></td>
      //               <td style="border-left:1px solid #fff;background-color: #A7DFF9; padding: 5px;"><b>Disciplinas</b></td>
      //             </tr>
      //             ${tr}
      //         </table>`

      tr += `${form[i].name} - ${form[i].schoolGrade} - ${_.orderBy(classSubjects)}. | \r\n`

      table = `${this.language.labelChildData} \r\n${tr}`
    }

    console.log(table)

    this.dados.setDados('appointment', { serviceNotes: table })


  }

}
