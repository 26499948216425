import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SweetAlertIcon } from 'sweetalert2'
import { DadosService } from './../../services/dados.service'

import { DOCUMENT } from '@angular/common'
import { Subscription, concatMap, take, tap } from 'rxjs'
import { Center } from 'src/app/models/center.model'
import { ApiService } from 'src/app/services/api.service'
import { ParamsService } from 'src/app/services/params.service'

import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import { CompletePage, ErrorMessage } from 'src/app/models/language.model'
import { AuthService } from 'src/app/services/auth.service'
import { LanguageService } from 'src/app/services/language.service'

dayjs.extend(utc)

type Status = 'concluido' | 'cancelado' | 'erro'

@Component({
  selector: 'app-concluido',
  templateUrl: './concluido.component.html',
  styleUrls: ['./concluido.component.scss']
})
export class ConcluidoComponent implements OnInit, OnDestroy {

  getData$!: Subscription
  cancel$!: Subscription

  language: CompletePage = this.languageService.completePage
  languageErrorMessage: ErrorMessage = this.languageService.errorMessage

  isLoading = false
  textLoading = this.language.loadingUpdateInfoAppoitment
  bookingStatus!: Status
  countryId: string = this.languageService.country

  // Urls
  pathname: string = window.location.pathname.replace('/concluido', '')

  siteUrl: string = `https://www.kumon${this.countryId === 'CL' ? '' : '.com'}.${this.countryId.toLowerCase()}`

  private centerUrl: string = `${this.pathname}?country=${this.countryId}`

  params: any

  // Site Center
  center: Center = {
    id: '',
    country: '',
    centerName: '',
    phone: '',
    courses: '',
    isVirtual: null,
    timeZone: '',
  }

  appointmentId: string = ''

  //Tela de Erro
  textMessage = this.languageErrorMessage.centerInfoNotLoaded
  labeMessageButton = this.language.buttonTryAgain

  //SweetAlert2
  AlertIcon: SweetAlertIcon = 'question'
  confirmRescheduleButtonText: string = this.language.alertConfirmReschedule
  confirmCancelButtonText: string = this.language.alertConfirmCancel
  AlerCancelButtonText: string = this.language.alertButtonCancel


  //Resumo agendamento
  unidade: string = ''
  date: string = ''
  time: string = ''
  location: string = ''
  phone: string = ''
  email: string = ''
  url: string = ''

  isOnline!: boolean

  constructor(
    private router: Router,
    private dados: DadosService,
    private apiService: ApiService,
    private auth: AuthService,
    private paramsService: ParamsService,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document
  ) { }


  ngOnInit(): void {

    window.scroll(0, 0)

    if (this.paramsService.getBookingParamsStatus()) {

      this.params = this.paramsService.getParams()
      this.getData()

    } else {
      this.irParaAgendamento()
    }
  }

  getData() {

    this.isLoading = true

    this.getData$ = this.auth.authAppointment()
      .pipe(

        concatMap(() => this.auth.authAgendamentoOnline()),

        concatMap(() => this.apiService.getCenter(this.centerUrl)),

        tap(res => {
          this.center = res
          console.log('SITE CENTER', this.center)
        }),

        concatMap(() => {
          return this.apiService.getAppointment(
            this.params.bookingId,
            this.params.appointmentId)
        }),

        tap(res => {
          console.log('GET APPOINTMENT', res)

          this.unidade = res.serviceLocation.displayName
          this.date = dayjs(res.start.dateTime).format('DD/MM/YYYY')
          this.time = dayjs(res.start.dateTime).utc().format('HH:mm')
          this.location = res.serviceLocation.address.street
          this.phone = res.serviceLocation.address.phone
          this.email = res.customers[0].emailAddress
          this.url = res.onlineMeetingUrl

          this.isOnline = !!res.onlineMeetingUrl
          this.appointmentId = res.id

          this.dados.setDados('params', {
            appointmentId: res.id,
            bookingId: this.params.bookingId
          })

          console.log(this.time)


        }),

        take(1)
      )
      .subscribe({
        next: res => {
          // console.log('Subscribe Next', res)
          this.isLoading = false
        },
        error: err => {
          this.textMessage = this.languageErrorMessage.centerInfoNotLoaded
          console.log('Subscribe Error', err)
          this.isLoading = false

          if (err.status === 400) {
            this.irParaAgendamento()
          }
        }
      })

  }


  cancelAppointment(): void {

    this.isLoading = true
    this.textLoading = this.language.loadingCancel


    this.cancel$ = this.apiService.cancelAppointment(
      this.params.bookingId,
      this.appointmentId
    ).subscribe({
      next: res => {
        console.log('cancelado', res)
        this.bookingStatus = 'cancelado'
        this.isLoading = false
      },
      error: err => {
        console.log('erro cancelado', err)
        this.textMessage = this.languageErrorMessage.unableToCancel
        this.bookingStatus = 'erro'
        this.isLoading = false
      }
    })
  }

  confirmaReagendamento() {
    return this.router.navigate([this.pathname], { queryParams: { bookingId: this.params.bookingId, appointmentId: this.appointmentId } })
  }

  irParaPaginaUnidade() {
    console.log(this.siteUrl)
    return window.location.href = this.siteUrl + this.pathname
  }

  irParaAgendamento() {
    return this.router.navigate([`${this.pathname}`], { queryParams: null, replaceUrl: true })
  }

  reloadPage() {
    window.location.reload()
  }

  ngOnDestroy(): void {
    if (this.paramsService.getBookingParamsStatus()) this.getData$.unsubscribe()
  }

}
