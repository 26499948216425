import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class ParamsService {

  constructor(
    private route: ActivatedRoute,
  ) {
    this.getQueryParams()
  }

  private hasBookingParams!: boolean
  // private temaParam = ''
  private params: any

  private getQueryParams() {

    this.route.queryParams.subscribe((res: any) => {
      this.params = res
      // this.temaParam = res.tema
      this.hasBookingParams = !!res.bookingId && !!res.appointmentId
    })
  }

  getBookingStatus(): boolean {
    return this.hasBookingParams
  }

  // getTemaParam() {
  //   return this.temaParam
  // }

  getParams() {
    return this.params
  }
}
