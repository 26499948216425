import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { environment } from '../../environments/environment'
import { Service } from '../models/service.model'
import { bookingService } from '../interceptors/authenticate.service'

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private readonly url = `${environment.API}/api`

  constructor(private http: HttpClient) { }

  private selectedServiceSubject = new Subject<Service>()
  selectedService$ = this.selectedServiceSubject.asObservable()


  // 1 - Site Center
  getCenter(centerUrl: string): Observable<any> {
    return this.http.post(`${this.url}/Site/center`, { centerUrl: centerUrl })
  }

  // 2 - Booking Service List
  bookingServiceList(bookingId: string): Observable<any> {
    return this.http.get(`${this.url}/BookingService/list`,
      {
        params: { bookingId },
        context: bookingService()
      }
    )
  }

  // 2.1 - Selected Service Subject
  selectedService(value: Service) {
    this.selectedServiceSubject.next(value)
  }

  // 3 - Staff Availability
  getStaffAvaiability(availability: any, bookingId: string): Observable<any> {
    return this.http.post(`${this.url}/Staff/availability`, availability,
      {
        params: { bookingId }
      }
    )
  }

  // 4.1 - Appointment NEW
  newAppointment(appointment: any, bookingId: string, online: boolean) {
    return this.http.post(`${this.url}/Appointment/new/${bookingId}/${online}`, appointment)
  }

  // 4.2 - Appointment GET
  getAppointment(bookingId: string, appointmentId: string): Observable<any> {
    return this.http.get(`${this.url}/Appointment/get/${bookingId}/${appointmentId}`)
  }

  // 4.3 - Appointment CANCEL
  cancelAppointment(bookingId: string, appointmentId: string) {
    return this.http.delete(`${this.url}/Appointment/delete/${bookingId}/${appointmentId}`)
  }

  // 4.4 - Appointment UPDATE
  updateAppointment(bookingId: string, appointmentId: string, body: any) {
    return this.http.put(`${this.url}/appointment/update/${bookingId}/${appointmentId}`, body)
  }

  // Appointment Email
  sendEmailContato(record: any): Observable<any> {
    return this.http.post(`${this.url}/Appointment/email`, record)
  }

}
