<!-- **** Loading **** -->
<app-loading *ngIf="isLoading" [textLoading]="textLoading"></app-loading>


<app-header [centerName]="center.centerName" [phone]="center.phone" [siteUrl]="siteUrl"></app-header>

<div [ngSwitch]="isAuthenticated">
  <div class="container-xl">
    <div class="wrapper">
      <ng-container *ngSwitchCase="true">
        <div class="conteudo">

          <!-- ** SERVIÇOS / INFORMAÇÕES DO RESPONSÁVEL ** -->
          <section [hidden]="hasAppointmentParams()  && !this.paramsService.getParams().hash">

            <div class="component" [hidden]="hasAppointmentParams()">
              <app-label
                [label]="language.labelService"
                icon="globe-americas"
                weight="solid"></app-label>

              <app-servicos
                [services]="servicesList"
                (formStatus)="getFormStatus($event, 'modalidade')">
                (change)='onSelectService()'
              </app-servicos>
            </div>
          </section>

          <!-- ** CALENDARIO / HORÁRIO ** -->
          <section>
            <div class="component">
              <app-label [label]="language.labelCalendar" icon="calendar"></app-label>

              <app-calendario
                [availableDays]="staffAvaiability"
                [defaultDuration]="defaultDuration"
                [calendarMaxRange]="calendarRange.end"
                (selectedDate)="onSelectDate($event)"
                (formStatus)="getFormStatus($event, 'calendario')">
              </app-calendario>

              <p class="email-modal" *ngIf="!this.paramsService.getParams().hash">
                {{ language.timeNotFindText }}
                <a href="" (click)="showModalContato($event)">{{language.timeNotFindLink}}</a>
              </p>
            </div>

            <div class="component">
              <app-label [label]="language.labelTimeSlot" icon="clock"></app-label>

              <app-hora-atendimento
                [slots]="slotInterval"
                [defaultDuration]="defaultDuration"
                (selectedTime)="onSelectTime($event)"
                (formStatus)="getFormStatus($event, 'duracao')">
              </app-hora-atendimento>

              <p class="fuso-horario-atual" *ngIf="hasTimezone">
                {{ language.timezone }}: {{ timezoneText }}
              </p>
            </div>
          </section>

          <!-- ** RESPONSÁVEL / ALUNO ** -->
          <section>
            <div class="component" *ngIf="!!this.paramsService.getParams().hash">
              <app-label
              [label]="language.labelParent"
              icon="user"
              weight="solid"></app-label>

              <app-info [nome]="guardianName"></app-info>
            </div>

            <div class="component" #responsavel *ngIf="!hasAppointmentParams() && !this.paramsService.getParams().hash">
              <app-label
                [label]="language.labelParent"
                [description]="language.labelRequired"
                icon="user"
                weight="solid"></app-label>

              <app-contato-responsavel
                (formStatus)=" getFormStatus($event, 'dadosResponsavel' )"></app-contato-responsavel>
            </div>

            <div class="component" *ngIf="!hasAppointmentParams() && !this.paramsService.getParams().hash">
              <app-label
                [label]="language.labelChild"
                [description]="language.labelOptional"
                icon="child"
                weight="solid"></app-label>

              <app-dados-aluno [disciplinas]="disciplinas"></app-dados-aluno>
            </div>
          </section>

        </div>

        <div class="politica-privacidade">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" (change)="onCheckPrivacy($event)" />
            <label class="form-check-label" for="privacidade">
              {{ language.privacyText }}
              <a [href]="goToPrivacy()" target="_blank"> {{ language.privacyLink }}.</a>
            </label>
          </div>
        </div>

        <!-- ** CONFIRMAR ** -->
        <div class="btn-confirmar" [ngSwitch]="hasAppointmentParams()">
          <app-button
            *ngSwitchCase="false"
            (click)="agendar()"
            [label]="buttonAgendar"
            leftIcon="fa-check"
            [disabled]="!isButtonConfirm"></app-button>

          <app-button
            *ngSwitchCase="true"
            (click)="reagendar()"
            [label]="buttonReagendar"
            leftIcon="fa-check"
            [disabled]="!isButtonConfirm"></app-button>
        </div>
      </ng-container>

      <!-- **** Mensagem de erro **** -->
      <ng-container *ngSwitchCase="false">
        <app-message [icon]="iconMessage">
          <p>
            {{ textMessage }}
          </p>
          <div *ngIf="isStaffAvailable; else formNotAvailable">
            <app-button [label]="labeMessageButton" (click)="tryAgain()"></app-button>
          </div>
          <ng-template #formNotAvailable>
            <div class="divNotAvailable">
              <p>
                {{ languageResquestService.textInfoPart1 }} <strong>{{center.centerName}}</strong> {{ languageResquestService.textInfoPart2 }}
                <a href="{{siteUrl}}/{{pathSearchCenter}}">{{ languageResquestService.textInfoPart3 }}</a>.
              </p>

              <app-contato-email (closeModal)="closeModal($event)" class="formNotAvailable" [bookingId]="bookingId"></app-contato-email>

            </div>
          </ng-template>
        </app-message>
      </ng-container>
    </div>
  </div>
</div>



<!-- **** Modal Solicitar Atendimento **** -->
<p-dialog
  [(visible)]="modalContato"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '60vw' }"
  [modal]="true"
  [draggable]="false"
  position="top"
  [resizable]="false"
  [dismissableMask]="true"
  transitionOptions="250ms cubic-bezier(.42, 0, .58, 1)">
  <ng-template pTemplate="header">
    <h1>{{ languageResquestService.modalTitle }}</h1>
  </ng-template>

  <div class="body">
    <app-contato-email (closeModal)="closeModal($event)" class="form-modal" [bookingId]="bookingId"></app-contato-email>
  </div>
</p-dialog>


<swal #sendEmail [title]="languageResquestService.alertSuccessTitle" icon="success"> </swal>

<swal
  #sendEmailErro
  [title]="languageResquestService.alertErrorTitle"
  [text]="languageResquestService.alertErrortext"
  icon="error">
</swal>

<app-version></app-version>